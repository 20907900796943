import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Loading from '../common/loading';
import api from '../../helpers/api';

class VerifyEmail extends Component {
  constructor() {
    super();
    this.state = { success: false, error: false };
    this.renderSuccess = this.renderSuccess.bind(this);
    this.renderError = this.renderError.bind(this);
    this.verifyToken = this.verifyToken.bind(this);
  }

  componentDidMount() {
    document.title = 'Verify Email | Flamingo Scooters';
    this.verifyToken();
  }

  verifyToken() {
    const token = this.props.match.params.token;

    if (token.length < 5) {
      this.setState({ error: 'Your verification token is invalid.' });
      return;
    }

    api.post('/sign-up/verify-email', { token })
      .then((res) => this.setState({ success: true }))
      .catch((e) => this.setState({ error: this.parseApiError(e) }));
  }

  parseApiError(err) {
    const errors = window.access(() => err.response.data.error.errors) ? err.response.data.error.errors : [];
    return window.access(() => errors[0].message) ? errors[0].message : (window.access(() => err.response.data.error.message) ? err.response.data.error.message : err.toString());
  }

  renderSuccess() {
    return (
      <div className="fm-container">
        <p className="fm-verify-email-title">Thank you!</p>
        <p className="fm-verify-email-text">Your email has been successfully verified. You can now close this window.</p>
        <Link className="fm-verify-email-button" to="/">Return to Website</Link>
      </div>
    );
  }

  renderError() {
    return (
      <div className="fm-container">
        <p className="fm-verify-email-title">Unable to Verify Email</p>
        <p className="fm-verify-email-text">{ this.state.error }</p>
        <p className="fm-verify-email-text">Please request a new verification email through the Flamingo app or contact our customer support.</p>
        <Link className="fm-verify-email-button" to="/">Return to Website</Link>
      </div>
    );
  }

  render() {
    return (
      <div className="fm-verify-email">
        { this.state.success ? this.renderSuccess() : (this.state.error ? this.renderError() : <Loading />) }
      </div>
    );
  }
}

export default VerifyEmail;
