import React, { Component } from 'react';
import { Router, Switch, Route } from 'react-router-dom';
import history from './helpers/history';

import Home from './components/home';
import Intoxication from './components/intoxication';
import VerifyEmail from './components/verify-email';

class App extends Component {
  render() {
    return (
      <Router history={history}>
        <div className="fm-app">
          <Switch>
            <Route path="/verify-email/:token" component={VerifyEmail} />
            <Route path="/intoxication" component={Intoxication} />
            <Route path="/" component={Home} />
          </Switch>
        </div>
      </Router>
    );
  }
}

export default App;
