import axios from 'axios';

const apiUrl = 'https://production.api.flamingoscooters.com';

const request = (url, method, data = null) => {
  const requestConfig = {
    url: apiUrl + url,
    method,
    headers: {},
  };

  if (data) {
    requestConfig.data = data;
  }

  return axios(requestConfig);
};

const get = (endpoint) => {
  return request(endpoint, 'GET', null);
};

const post = (endpoint, data) => {
  return request(endpoint, 'POST', data);
};

const api = { get, post };

export default api;
