import React, { Component } from 'react';

class Intoxication extends Component {
  constructor() {
    super();
    this.state = { status: 'welcome', sign: false, stopTime: false, reaction: false, attempts: 0 };

    this.signTimeout = null;
    this.speedSigns = 0;

    this.startGame = this.startGame.bind(this);
    this.prepareSign = this.prepareSign.bind(this);
    this.showSign = this.showSign.bind(this);
    this.missedStop = this.missedStop.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.speedFinished = this.speedFinished.bind(this);
  }

  componentDidMount() {
    document.title = 'Flamingo Scooters';
  }

  startGame() {
    if (this.signTimeout) {
      clearTimeout(this.signTimeout);
    }
    this.speedSigns = 0;
    this.setState({ status: 'playing' }, this.prepareSign);
  }

  prepareSign() {
    this.setState({ sign: false })
    const signDelay = (Math.floor(Math.random() * 4) + 1) * 1000;
    this.signTimeout = setTimeout(this.showSign, signDelay);
  }

  showSign() {
    const type = Math.floor(Math.random() * 2);
    if (type === 0 || this.speedSigns === 2) {
      this.setState({ sign: 'stop', stopTime: Date.now() });
      this.signTimeout = setTimeout(this.missedStop, 3500);
    } else {
      ++this.speedSigns;
      this.setState({ sign: 'speed' });
      this.signTimeout = setTimeout(this.speedFinished, 3500);
    }
  }

  missedStop() {
    var { status } = this.state;
    if (status === 'playing') {
      status = 'missed';
    }
    this.setState({ sign: false, status });
  }

  speedFinished() {
    const { status } = this.state;
    if (status === 'playing') {
      this.prepareSign();
    } else {
      this.setState({ sign: false });
    }
  }

  handleClick() {
    const { stopTime, sign, attempts } = this.state;
    if (sign === 'stop') {
      const reaction = Date.now() - stopTime - 320;
      const status = reaction > 1000 ? 'failed' : 'passed';
      this.setState({ status, reaction, attempts: attempts + 1 });
    } else {
      this.setState({ status: 'early' });
    }
  }

  handlePassed() {
    window.location = 'https://app.rideflamingo.com/restriction/passed';
  }

  handleFailed() {
    window.location = 'https://app.rideflamingo.com/restriction/failed';
  }

  renderOverlay(status, reaction, attempts) {
    switch (status) {
      case 'passed':
        return (
          <div className="fm-intoxication-overlay">
            <div className="fm-intoxication-overlay-container">
              <p className="fm-intoxication-title">Passed!</p>
              <p className="fm-intoxication-description">You tapped the screen { reaction }ms after the stop sign appeared!</p>
              <p className="fm-intoxication-description">You can now continue to your scooter.</p>
              <button className="fm-intoxication-button-play" onClick={this.handlePassed}>Unlock Scooter</button>
            </div>
          </div>
        );
      case 'failed':
        return (
          <div className="fm-intoxication-overlay">
            <div className="fm-intoxication-overlay-container">
              <p className="fm-intoxication-title">Too Slow!</p>
              <p className="fm-intoxication-description">You tapped the screen { reaction }ms after the stop sign appeared.</p>
              {
                attempts < 2 ? (
                  <>
                    <p className="fm-intoxication-description">You must scooter below 1000ms to ride a scooter at this time.</p>
                    <button className="fm-intoxication-button-try" onClick={this.startGame}>Try Again</button>
                    <button className="fm-intoxication-button-cancel" onClick={this.handleFailed}>Close</button>
                  </>
                ) : (
                  <>
                    <p className="fm-intoxication-description">You must score below 1000ms to ride a scooter at this time. You can try again in 10 minutes.</p>
                    <button className="fm-intoxication-button-cancel" onClick={this.handleFailed}>Close</button>
                  </>
                )
              }
            </div>
          </div>
        );
      case 'early':
        return (
          <div className="fm-intoxication-overlay">
            <div className="fm-intoxication-overlay-container">
              <p className="fm-intoxication-title">Too Soon!</p>
              <p className="fm-intoxication-description">It looks like you tapped before the stop sign appeared.</p>
              <p className="fm-intoxication-description">Tap as soon as you see a "STOP" sign appear on your screen.</p>
              <button className="fm-intoxication-button-try" onClick={this.startGame}>Try Again</button>
              <button className="fm-intoxication-button-cancel" onClick={this.handleFailed}>Close</button>
            </div>
          </div>
        );
      case 'missed':
        return (
          <div className="fm-intoxication-overlay">
            <div className="fm-intoxication-overlay-container">
              <p className="fm-intoxication-title">Too Slow!</p>
              <p className="fm-intoxication-description">It looks like you missed the stop sign.</p>
              <p className="fm-intoxication-description">Tap as soon as you see a "STOP" sign appear on your screen.</p>
              <button className="fm-intoxication-button-try" onClick={this.startGame}>Try Again</button>
              <button className="fm-intoxication-button-cancel" onClick={this.handleFailed}>Close</button>
            </div>
          </div>
        );
      default:
        return (
          <div className="fm-intoxication-overlay">
            <div className="fm-intoxication-overlay-container">
              <p className="fm-intoxication-title">Intoxication Test</p>
              <p className="fm-intoxication-description">To ride a scooter at this time you must pass a reaction test.</p>
              <p className="fm-intoxication-description">As soon as you see a "STOP" sign tap the screen.</p>
              <button className="fm-intoxication-button-play" onClick={this.startGame}>Play</button>
            </div>
          </div>
        );
    }
  }

  render() {
    const { sign, status, reaction, attempts } = this.state;
    const stopClass = sign === 'stop' ? 'fm-intoxication-stop fm-intoxication-sign-move' : 'fm-intoxication-stop';
    const speedClass = sign === 'speed' ? 'fm-intoxication-speed fm-intoxication-sign-move' : 'fm-intoxication-speed';
    const showOverlay = ['welcome', 'missed', 'failed', 'passed', 'early'].includes(status);

    return (
      <div className="fm-intoxication">
        <div className="fm-intoxication-player" onClick={this.handleClick}>
          <div className="fm-intoxication-game">
            { !showOverlay && <p className="fm-intoxication-banner">Tap the screen when you see the stop sign</p> }
            <div className="fm-intoxication-grass"></div>
            <div className="fm-intoxication-rider"></div>
            <div className={stopClass}></div>
            <div className={speedClass}></div>
          </div>
        </div>
        { showOverlay && this.renderOverlay(status, reaction, attempts) }
      </div>
    )
  }
}

export default Intoxication;
